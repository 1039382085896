export default class ModelUserInfo {
  static firstName: string;
  static lastName: string;
  static jobTitle: string;
  static email: string;
  static auth: boolean = false;
  static searchBar: string = "";
  static info: any = {};

  static async isConnected() {
    await fetch(process.env.REACT_APP_BackEndPoint + "/isconnected", {
      credentials: "include",
    })
      .then(async (res) => {
        let json = await res.json();
        return json;
      })
      .then(async (json) => {
        if (json.isConnected) {
          ModelUserInfo.firstName = json.name;
          ModelUserInfo.auth = true;
          ModelUserInfo.info = json.info;
          console.log(json.info);
        } else {
          window.location.href =
            process.env.REACT_APP_BackEndPoint! +
            "?source=" +
            window.location.href;
        }
      })
      .catch((error: Error) => {
        console.log(error);
      });

    return ModelUserInfo.auth;
  }
}
