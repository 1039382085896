export async function generateQrCode(
  colorFront: string,
  colorBack: string,
  data: string
) {
  let headers = new Headers();
  headers.append("x-api-key", process.env.REACT_APP_ApiGetWay!);
  let payload = encodeURIComponent(data);
  let result: any = await fetch(
    process.env.REACT_APP_BackEndPoint +
      "/qrcode?data=" +
      payload +
      "&colorFront=" +
      encodeURI(colorFront) +
      "&colorBack=" +
      encodeURI(colorBack),
    {
      headers: headers,
    }
  ).catch((error: Error) => {
    console.log(error);
  });
  let json = await result.json();
  return json.qrCode;
}
