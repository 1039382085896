import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ModelUserInfo from "../../Models/ModelUserInfo";
import Spinner from "../Element/Spinner/Spinner";

export default function ProtectedRoute(children: any) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!ModelUserInfo.auth) {
      ModelUserInfo.isConnected().then((isConnected) => {
        if (isConnected) {
          setIsLoading(false);
        }
      });
    }
  }, []);

  console.log(ModelUserInfo.auth);
  return isLoading ? (
    <Spinner />
  ) : ModelUserInfo.auth ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );

  //return <h2>Home (Protected: authenticated user required)</h2>;
}
