import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TallyValidation from "./Element/TallyValidation";
import { HallEvent } from "../../../Type";
import Spinner from "../../Element/Spinner/Spinner";
import TallyCall from "./Element/TallyCall";

export default function TallyList(props: any) {
  const [isLoading, setIsLoading] = useState<boolean>(true); // condition to display the spinner
  const [alertMessage, setAlertMessage] = useState(0);
  const [event, setEvent] = useState<HallEvent | null>(null); // list of all hall event
  const [searchParams] = useSearchParams();

  useEffect(() => {
    getEvent()
      .then((response) => {
        console.log(response);
        let id = searchParams.get("id");
        if (id != null) {
          let event: HallEvent | null = listEventFilter(response, id);
          if (event != null) setEvent(event);
        }
      })
      .then(() => setIsLoading(false));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {isLoading ? (
        <Spinner></Spinner>
      ) : alertMessage === 0 ? (
        <TallyValidation
          setIsLoading={setIsLoading}
          eventAlertMessage={setAlertMessage}
          hallEvent={event!}
        ></TallyValidation>
      ) : (
        <TallyCall hallEvent={event!}></TallyCall>
      )}
    </div>
  );
}

/**
 * Fetch the event of the day
 * @returns HallEvent[] object array
 */
async function getEvent(): Promise<HallEvent[]> {
  let headers = new Headers();
  headers.append("x-api-key", process.env.REACT_APP_ApiGetWay!);
  let siteID: string = encodeURI(process.env.REACT_APP_SiteID!);
  let listID: string = encodeURI(process.env.REACT_APP_ListID!);

  let url =
    process.env.REACT_APP_BackEndPoint +
    "/list?siteID=" +
    siteID +
    "&listID=" +
    listID;
  let response: any = await fetch(url, {
    headers: headers,
  }).catch((error: Error) => {
    console.log(error);
  });
  let json = await response.json();

  return json;
}

/**
 * make an iteration on the list to get and format the event
 * @param listEvent tyhe list
 * @param id id of the element to update
 * @returns an HallEvent element
 */
function listEventFilter(listEvent: HallEvent[], id: string): HallEvent | null {
  let buffer: HallEvent | null = null;
  listEvent.forEach((element: HallEvent) => {
    if (element.id === id) {
      element.DisplayNameofVisitors = element.Title;
      buffer = element;
      return;
    }
  });
  return buffer;
}
