import { Typography } from "@mui/material";
import React, { useState } from "react";
import "./clock.css";

function formatDate(date: Date) {
  let days: any = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  let months: any = [
    "janvier",
    "fevrier",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "decembre",
  ];

  return (
    days[date.getDay()] +
    " " +
    twoDigitFormat(date.getDate()) +
    " " +
    months[date.getMonth()] +
    " " +
    twoDigitFormat(date.getHours()) +
    ":" +
    twoDigitFormat(date.getMinutes()) +
    ":" +
    twoDigitFormat(date.getSeconds())
  );
}

function twoDigitFormat(i: number) {
  return i < 10 ? "0" + i.toString() : i.toString();
}

export function Clock() {
  const [dateOrleans, setDateOrleans] = useState(new Date());

  setTimeout(() => {
    setDateOrleans(
      new Date(new Date().toLocaleString("en-US", { timeZone: "Europe/Paris" }))
    );
  }, 1000);

  return (
    <div className="clock">
      <Typography variant="h2" component="div">
        {formatDate(dateOrleans)}
      </Typography>
    </div>
  );
}
