import { useState } from "react";
import Spinner from "../../Element/Spinner/Spinner";
import TallyCreation from "./Element/TallyCreation";

export default function TallyList(props: any) {
  const [isLoading, setIsLoading] = useState<boolean>(false); // condition to display the spinner

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {isLoading ? <Spinner></Spinner> : <TallyCreation></TallyCreation>}
    </div>
  );
}
