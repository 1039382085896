import { Clock } from "../Clock/Clock";
import { Typography } from "@mui/material";
import { generateQrCode } from "../../../../Utilities/Various";
import { useEffect, useState } from "react";

export function MainInformation(props: any) {
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    generateQrCode(
      "000000",
      "FFFFFF",
      process.env.REACT_APP_FrontEndPoint + "/tallymaker"
    ).then((response) => setQrCode(response));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        style={{ width: "800px" }}
        src="./logoAltyor.png"
        alt="logo altyor"
      />
      <Typography variant="h2" component="div">
        Vous n'avez pas rendez-vous ?
      </Typography>
      <Typography variant="h4" component="div">
        Appelez le standard ci-dessous
      </Typography>
      <Typography variant="body1" component="div">
        NodOn : 09.72.37.78.75
      </Typography>
      <Typography variant="body1" component="div">
        Tiloli : 02.38.63.41.71
      </Typography>
      <Typography variant="body1" component="div">
        Altyor : 02.38.25.00.90
      </Typography>
      <Typography variant="body1" component="div">
        Altyor RH : 09.72.54.45.88
      </Typography>
      <Typography variant="body1" component="div">
        Si vous obtennez un rendez-vous, émargez en scannant le qrCode suivant :
      </Typography>
      <img
        style={{ width: "150px", height: "150px", padding: "15px" }}
        src={qrCode}
        alt="Emargez"
      />

      <Clock></Clock>
    </div>
  );
}
