import React, { useEffect, useRef, useState, useCallback } from "react";
import "./progressBar.css";

export function ProgressBar(props: {
  eventPageManager: Function; 
  eventFold: () => void;
  page: number;
  numberOfPage: number;
}) {
  const [width, setWidth] = useState(0);
  const [isFold, setIsFold] = useState(false);
  const childRef = useRef<HTMLDivElement>(null);
  const parentBarRef = useRef<HTMLDivElement>(null);

  const animate = useCallback(() => {
    if (childRef.current && parentBarRef.current) {
      const newWidth = width + 1;
      if (newWidth >= parentBarRef.current.offsetWidth) {
        props.eventPageManager();
        setWidth(0);
        setIsFold(false);
      } else {
        if (newWidth > 490 - 490 / 20 - 5 && !isFold) {
          props.eventFold();
          setIsFold(true);
        }
        childRef.current.style.width = `${newWidth}px`;
        setWidth(newWidth);
      }
    }
  }, [width, isFold, props.eventPageManager, props.eventFold]);

  useEffect(() => {
    const animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [animate]);

  const bulletPage = setNumberOfEventBullet(props.page + 1, "white");
  const bulletVoid = setNumberOfEventBullet(
    props.numberOfPage - (props.page + 1),
    "black"
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        bottom: "0px",
        width: "490px",
      }}
    >
      <div id="myProgress" ref={parentBarRef}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {bulletPage}
          {bulletVoid}
        </div>
        <div id="myBar" ref={childRef}></div>
      </div>
    </div>
  );
}

function setNumberOfEventBullet(numberOfEvent: number, color: string) {
  return Array.from({ length: numberOfEvent }, (_, i) => (
    <div style={{ padding: "2px" }} key={i}>
      <svg height="20px" width="20px">
        <circle cx="10" cy="10" r="10" fill={color} />
        <title>Event bullet</title>
      </svg>
    </div>
  ));
}
