import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import "../tally.css";
import { ReactElement, useState } from "react";

export default function TallyCreation() {
  const [status, setStatus] = useState(0);
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [company, setCompany] = useState("");
  let [email, setEmail] = useState("");

  let welcome: ReactElement = (
    <Typography variant="h4" component="div" textAlign={"center"}>
      Merci de votre coopération et bienvenue chez Altyor
    </Typography>
  );

  let createTallyForm: ReactElement = (
    <>
      <Typography variant="h4" component="div" textAlign={"center"}>
        Pour émarger, remplissez ce formulaire :
      </Typography>
      <div id="formTallyCreation">
        <TextField
          id="outlined-basic"
          label="FirstName"
          variant="outlined"
          onChange={(event) => setFirstName(event.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="LastName"
          variant="outlined"
          onChange={(event) => setLastName(event.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Company"
          variant="outlined"
          onChange={(event) => setCompany(event.target.value)}
        />

        <div id="buttonLine">
          <Button variant="contained" onClick={redirectLinkedin}>
            Cancel
          </Button>
          <Button
            disabled={firstName === "" || lastName === "" || email === ""}
            variant="contained"
            onClick={(event) =>
              postTallyLine(
                event,
                firstName + " " + lastName,
                company,
                email,
                setStatus
              )
            }
          >
            Ok
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {status === 0 ? createTallyForm : welcome}
    </div>
  );
}

function postTallyLine(
  event: React.MouseEvent<HTMLElement>,
  name: string,
  company: string,
  email: string,
  eventHandler: Function
) {
  let headers = new Headers();
  headers.append("x-api-key", process.env.REACT_APP_ApiGetWay!);
  headers.append("Content-Type", "application/json");
  let body: any = {
    siteID: process.env.REACT_APP_SiteID!,
    listID: process.env.REACT_APP_ListID!,
    name: name,
    company: company,
    email: email,
  };
  fetch(process.env.REACT_APP_BackEndPoint + "/list", {
    method: "POST",
    body: JSON.stringify(body),
    headers: headers,
  })
    .then(eventHandler(1))
    .catch((error: Error) => console.log(error));
}

function redirectLinkedin(event: React.MouseEvent<HTMLElement>) {
  window.location.href = "https://fr.linkedin.com/company/altyor";
}
