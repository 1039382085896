import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./CSS/App.css";
import ProtectedRoute from "./Components/Utilities/ProtectedRoute";
import Home from "./Components/Page/Home/Home";
import TallyList from "./Components/Page/TallyHandler/TallyHandler";
import TallyMaker from "./Components/Page/TallyHandler/TallyMaker";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/list" element={<TallyList />} />
        <Route path="/TallyMaker" element={<TallyMaker />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/admin" element={<></>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
