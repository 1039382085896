import { Typography } from "@mui/material";
import "./paginer.css";
import { PaginerEssential, HallEvent } from "../../../../../../Type";
import { ReactElement, RefObject, createRef } from "react";
import { ProgressBar } from "../ProgressBar/ProgressBar";

export function Paginer(props: PaginerEssential) {
  const parentDivLeft: RefObject<HTMLDivElement> = createRef();
  const childDiv: RefObject<HTMLDivElement> = createRef();
  const eventAnim = () => animFold(parentDivLeft, childDiv);

  animUnFold(parentDivLeft, childDiv);

  const today = new Date().toISOString().split('T')[0];
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0];

  let page = createPage(props.events, props.page, props.numberOfEventPerPage, today, tomorrow);
  
  const todayEvents = page.filter(event => isSameDay(event.Date, today));
  const tomorrowEvents = page.filter(event => isSameDay(event.Date, tomorrow));

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
        ref={childDiv}
      >
        {todayEvents.length > 0 && (
          <>
            <Typography 
              variant="h5" 
              align="center" 
              color="Black" 
              style={{ marginBottom: '4px', marginTop: '4px', padding: '2px' }}
            >
              Aujourd'hui
            </Typography>            
            {todayEvents.map((element: HallEvent, index: number) => {
              return eventDisplayer(element, index);
            })}
          </>
        )}
        {tomorrowEvents.length > 0 && (
          <>
          <Typography 
            variant="h5" 
            align="center" 
            color="Black"
            style={{ marginBottom: '4px', marginTop: '4px', padding: '2px' }}
          >
            Demain
          </Typography>
            {tomorrowEvents.map((element: HallEvent, index: number) => {
              return eventDisplayer(element, index);
            })}
          </>
        )}
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#f28f22",
          position: "absolute",
          left: "0px",
        }}
        ref={parentDivLeft}
      ></div>
      {props.numberOfEventPerPage >= props.events.length ? (
        <></>
      ) : (
        <ProgressBar
          eventPageManager={props.eventPageManager}
          eventFold={eventAnim}
          page={props.page}
          numberOfPage={Math.ceil(
            props.events.length / props.numberOfEventPerPage
          )}
        ></ProgressBar>
      )}
    </>
  );
}

function createPage(
  array: HallEvent[],
  page: number,
  numberOfEventPerPage: number,
  today: string,
  tomorrow: string
): HallEvent[] {
  const todayEvents = array.filter(event => isSameDay(event.Date, today));
  const tomorrowEvents = array.filter(event => isSameDay(event.Date, tomorrow));
  
  const allEvents = [...todayEvents, ...tomorrowEvents];
  
  let buffer: HallEvent[] = [];
  if (
    allEvents.length - 1 <
    page * numberOfEventPerPage + numberOfEventPerPage - 1
  ) {
    buffer = allEvents.slice(page * numberOfEventPerPage, allEvents.length);
  } else {
    buffer = allEvents.slice(
      page * numberOfEventPerPage,
      page * numberOfEventPerPage + numberOfEventPerPage
    );
  }
  return buffer;
}


function eventDisplayer(element: HallEvent, index: number): ReactElement {
  let masterDisplayName = element.DisplayNameofVisitors;

  return (
    <div className="eventLine" key={index}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          flexGrow: "1",
        }}
      >
        <Typography variant="h6">{masterDisplayName}</Typography>
        <Typography variant="body1">
          Hôte : {formatName(element.AltyorMateString)}
        </Typography>
        <Typography variant="body1">Scannez le QR code</Typography>
        <Typography variant="body1">
          ou contactez-le au {formatPhone(element.TelephoneAltyorMate)}
        </Typography>
      </div>
      <img
        style={{ width: "150px", height: "150px", padding: "15px" }}
        src={element.QrCode}
        alt={element.Title + " " + element.LastName + " QrCode"}
      />
    </div>
  );
}

function isSameDay(date1: string, date2: string): boolean {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getFullYear() === d2.getFullYear() &&
         d1.getMonth() === d2.getMonth() &&
         d1.getDate() === d2.getDate();
}

function formatPhone(phone: string): string {
  let buffer = "";
  for (let i = 2; i < phone.length + 2; i += 2) {
    buffer += phone.substring(i - 2, i);
    if (i !== phone.length) buffer += ".";
  }
  return buffer;
}

function formatName(name: string): string {
  let nameElement = name.split(" ");
  let buffer = "";
  nameElement.forEach((element: string) => {
    let helper: string = element.toLowerCase();
    buffer += " " + element[0].toUpperCase();
    buffer += helper.substring(1);
  });

  return buffer;
}

function animFold(
  parentDivLeft: RefObject<HTMLDivElement>,
  childDiv: RefObject<HTMLDivElement>
) {
  setTimeout(() => {
    if (parentDivLeft.current != null && childDiv.current != null) {
      if (parentDivLeft.current.offsetWidth < childDiv.current.offsetWidth) {
        parentDivLeft.current.style.width =
          parentDivLeft.current.offsetWidth + 2 + "px";
        animFold(parentDivLeft, childDiv);
      }
    }
  }, 1000 / 490);
}

function animUnFold(
  parentDivLeft: RefObject<HTMLDivElement>,
  childDiv: RefObject<HTMLDivElement>
) {
  setTimeout(() => {
    if (parentDivLeft.current != null) {
      if (parentDivLeft.current.offsetWidth > 0) {
        parentDivLeft.current.style.width =
          parentDivLeft.current.offsetWidth - 2 + "px";
        animUnFold(parentDivLeft, childDiv);
      }
    }
  }, 1000 / 490);
}
