import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { HallEvent } from "../../../../Type";

export default function TallyList(props: { hallEvent: HallEvent }) {
  let questionTwoElement: any = (
    <>
      <Typography variant="h4" component="div" textAlign={"center"}>
        Un message texte a été envoyé a
        {" " + props.hallEvent.AltyorMateString + ". "}
        Pour l'appeler cliquez sur appeler, pour être redirigé sur linkedin,
        cliquez sur linkedin. Sinon, fermez votre navigateur.
      </Typography>
      <div>
        <Button variant="contained" onClick={infirmationTwo}>
          Linkedin
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: "10px" }}
          onClick={(e) => confirmationTwo(e, props.hallEvent)}
        >
          Appeler
        </Button>
      </div>
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {questionTwoElement}
    </div>
  );
}

function confirmationTwo(
  e: React.MouseEvent<HTMLElement>,
  hallEvent: HallEvent
) {
  window.location.href = "tel:" + hallEvent.TelephoneAltyorMate;
}

function infirmationTwo(event: React.MouseEvent<HTMLElement>) {
  window.location.href = "https://fr.linkedin.com/company/altyor";
}
