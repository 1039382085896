import { useState } from "react";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { HallEvent } from "../../../../Type";

export default function TallyList(props: {
  setIsLoading: Function;
  eventAlertMessage: Function;
  hallEvent: HallEvent;
}) {
  const [cancelMessage, setCancelMessage] = useState(false);
  let tallyConfirmList: string[] = [];

  let displayNameofVisitors: string = props.hallEvent.DisplayNameofVisitors;
  let displayNameofVisitorsArray: string[] = displayNameofVisitors.split(",");

  if (displayNameofVisitorsArray.length === 1)
    tallyConfirmList.push(displayNameofVisitorsArray[0]);

  const eventCheckedBox: any = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      tallyConfirmList = buildArrayOfCheckedBox(
        tallyConfirmList,
        event.target.value
      );
    } else {
      tallyConfirmList = unbuildArrayOfCheckedBox(
        tallyConfirmList,
        event.target.value
      );
    }
    console.log(tallyConfirmList);
  };

  function confirmationOne(event: React.MouseEvent<HTMLElement>) {
    props.setIsLoading(true);
    let headers = new Headers();
    headers.append("x-api-key", process.env.REACT_APP_ApiGetWay!);
    headers.append("Content-Type", "application/json");
    let body: any = {
      siteID: process.env.REACT_APP_SiteID!,
      listID: process.env.REACT_APP_ListID!,
      eventID: props.hallEvent.id,
      tallyConfirmList: tallyConfirmList.join(","),
    };
    fetch(process.env.REACT_APP_BackEndPoint + "/list", {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: headers,
    })
      .then((res) => res.json())
      .then((json) => console.log(json))
      .then((json) => props.eventAlertMessage(1))
      .catch((error: Error) => console.log(error))
      .finally(() => props.setIsLoading(false));
  }

  function infirmationOne(event: React.MouseEvent<HTMLElement>) {
    setCancelMessage(true);
  }

  let questionElement = (
    <>
      {" "}
      <Typography variant="h4" component="div" textAlign={"center"}>
        Cochez les cases des personnes présentes
      </Typography>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {displayNameofVisitorsArray.map((element, index) => {
          return (
            <FormControlLabel
              key={index}
              control={<Checkbox name={element} />}
              onClick={eventCheckedBox}
              label={element}
              value={element}
            />
          );
        })}
      </div>
      <div>
        <Button variant="contained" onClick={infirmationOne}>
          Annuler
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: "10px" }}
          onClick={confirmationOne}
        >
          Valider
        </Button>
      </div>
    </>
  );

  let questionSingleElement = (
    <>
      {" "}
      <Typography variant="h4" component="div" textAlign={"center"}>
        Êtes vous bien {displayNameofVisitorsArray[0]}
      </Typography>
      <div>
        <Button variant="contained" onClick={infirmationOne}>
          Non
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: "10px" }}
          onClick={confirmationOne}
        >
          Oui
        </Button>
      </div>
    </>
  );

  let questionElementCondition =
    displayNameofVisitorsArray.length === 1
      ? questionSingleElement
      : questionElement;

  let cancelElement = (
    <Typography variant="h4" component="div" textAlign={"center"}>
      S'il vous plait, rescannez votre QrCode.
    </Typography>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {cancelMessage ? cancelElement : questionElementCondition}
    </div>
  );
}

function buildArrayOfCheckedBox(array: string[], value: string): string[] {
  if (!array.includes(value)) {
    array.push(value);
  }
  return array;
}
function unbuildArrayOfCheckedBox(array: string[], value: string): string[] {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === value) {
      array.splice(i, 1);
      break;
    }
  }
  return array;
}
