import React from "react";
import { Event } from "./Element/Event/Event";
import { MainInformation } from "./Element/MainInformation/MainInformation";

function Main() {
  return (
    <div className="extended" style={{ justifyContent: "center" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <MainInformation></MainInformation>
        <Event></Event>
      </div>
    </div>
  );
}

export default Main;
